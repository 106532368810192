@import "./mixins.scss";

$defaultBorder: 1px solid #cecece;

.recommendation-list-container {
  max-height: calc(100vh - 7.5rem);
  overflow: auto;
  @include custom-scroll-bar();
}

@media (min-width: 601px) and (max-width: 1023px) {
  .recommendation-list-container {
    max-height: calc(100vh - 113px);
  }
}

@media (max-width: 600px) {
  .recommendation-list-container {
    max-height: calc(100vh - 140px);
  }
}

.search-highlight {
  background: #ffefaf;
}

.recommendation-toggle {
  position: relative;
  .inner {
    z-index: 10;
    &:after {
      position: absolute;
      right: 0.5rem;
      transition: transform 0.3s ease-in-out;
      @include font-icon("\e900");
    }
    &.expanded {
      &:after {
        transform: rotate(180deg);
      }
    }
    &.collapsed {
      &:after {
        transform: rotate(0deg);
      }
    }
  }
}

.recommendation-header-link {
  &:first-child {
    margin-right: 5px;
  }
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
  font-size: 14px;
}

.page-title--search {
  background: url("../assets/search.svg") no-repeat -5px -2px;
}

.page-title--gear {
  background: url("../assets/gear.svg") no-repeat -5px -2px;
}

.page-title--about {
  background: url("../assets/about.svg") no-repeat -5px -2px;
}

.page-title--main-page {
  background: url("../assets/covid.svg") no-repeat center left;
  background-size: 60px;
}

.page-title--recommendations {
  background: url("../assets/list-white.svg") no-repeat -5px 7px;
}

.page-title--grid {
  background: url("../assets/grid-white.svg") no-repeat -5px 7px;
}

.external-link {
  &:hover {
    opacity: 0.75;
  }
  &:after {
    content: " ";
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-left: 5px;
    background: url("../assets/external-link-icon.svg") no-repeat 50% 50%;
  }
}

.text-whoCovid-100 {
  &:hover {
    opacity: 0.75;
  }
  color: #008dc9;
}

.recommendation-item {
  border: $defaultBorder;
  .group-name {
    margin-right: 5px;
    padding: 0px 5px;
    &.group-name--infant {
      background-color: #fde0b3;
    }
    &.group-name--child {
      background-color: #fde0b3;
    }
    &.group-name--adolescent {
      background-color: #fcc3fc;
    }
    &.group-name--adult {
      background-color: #c8e1fb;
    }
  }
}

.recommendation-icon {
  display: block;
  width: 21px;
  min-width: 21px;
  height: 21px;
  margin-right: 10px;
  border-radius: 50%;
  &.recommend-against {
    border: 1px solid red;
    background: url("../assets/recommend-against.svg") center center;
  }

  &.conditional,
  &.suggest-either {
    border: 1px solid green;
    background: url("../assets/either.svg") center center;
  }
  &.suggest-against {
    border: 1px solid red;
    background: url("../assets/conditional-recommend-against.svg") center center;
  }

  &.suggest-for {
    border: 1px solid green;
    background: url("../assets/conditional-recommend-for.svg") center center;
  }
  &.strong-for,
  &.recommend-for {
    border: 1px solid green;
    background: url("../assets/recommend-for.svg") center center;
  }
}

.border-bottom--whoCovid-100 {
  border-bottom: 1px solid #008dc9;
}
