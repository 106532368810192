.bg-gray-opacity30 {
  background: rgba(228, 228, 228, 0.3);
}

.about-page--partners {
  img,
  span {
    margin-right: 2rem;

    &:last-child {
      margin-right: 0;
    }
  }

  img {
    &.cihr {
      height: 4.5rem;
    }
    &.evidence-prime {
      height: 2.2rem;
    }
    &.who {
      height: 5rem;
    }
  }

  span {
    line-height: 3.75rem;
    font-size: 3.75rem;

    &.who {
      color: #103c81;
    }
  }
}
